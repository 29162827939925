import WsProvider from './provider/WSProvider'


var serviceName = "webservis";
var moduleName = "kurum";


var kurumService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    kurumListele(kurumDurumu, baslangic, limit){
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            kurumDurumu,
            baslangic, 
            limit,
            serviceName: moduleName,
            methodName: "kurumListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    kurumDetay(kurumID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            kurumID,
            serviceName: moduleName,
            methodName: "kurumDetay"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    kurumEkle(kurumAdi, kurumDurumu, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            kurumAdi: kurumAdi,
            kurumDurumu: kurumDurumu,
            durum: durum,
            methodName: "kurumEkle",
            serviceName: moduleName
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    kurumArama(kurumAdi, kurumDurumu, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            kurumAdi,
            kurumDurumu,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "kurumArama"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    musteriSayisi(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            durum: durum,
            serviceName: moduleName,
            methodName: "musteriSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    
    kurumGuncelle(kurumID, kurumAdi, kurumDurumu) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            kurumID,
            kurumAdi,
            kurumDurumu,
            serviceName: moduleName,
            methodName: "kurumGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

}

export default kurumService