<template>
    <div class="page-table scrollable only-y mainDiv mb-30">
        <div class="card-base baslikBg animated fadeInUp">
            Evrak Listesi          
            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="top" v-if="yetkiTuru != '3'">
                    <el-button v-on:click="routeKurum('Evrak Tanımla')" size="mini" type="text" icon="el-icon-plus"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || searchLoading || konuLoading || kurumLoading || addLoading || evrakLoading"
        :element-loading-text='loading ? "Evrak Listesi Getiriliyor. Lütfen Bekleyiniz." : searchLoading ? "Evrak Aranıyor. Lütfen Bekleyiniz." : konuLoading ? "Evrak Konu Güncelleniyor. Lütfen Bekleyiniz."
        : addLoading ? "Boş Evrak Ekleniyor. Lütfen Bekleyiniz." : kurumLoading ? "Cari Listesi Getiriliyor. Lütfen Bekleyiniz." : addLoading ? "Evrak Siliniyor. Lütfen Bekleyiniz." : "Evrak Yükleniyor. Lütfen Bekleyiniz."'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-row class="mt-0 mb-10">
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                    <!--
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray" :disabled="searchLoading">
                        <el-radio-button label="1">
                            <i class="mdi mdi-cards-outline mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                            <transition name="slide-fade">
                                <label v-if="evrakDurumu =='I'">Gelen Evraklar</label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="2">
                            <i class="mdi mdi-cards-outline mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                            <transition name="slide-fade">
                                <label v-if="evrakDurumu =='O'">Giden Evraklar</label>
                            </transition>
                        </el-radio-button>
                    </el-radio-group>
                    </div> --> 
                    <div>
                        <el-radio-group v-on:change="handleChange($event)" v-model="radio" size="medium">
                            <el-badge :value="gelenCount" class="item evrak"><el-radio-button label="1" class="gelen">Gelen Evraklar</el-radio-button></el-badge>
                            <el-badge :value="gidenCount" class="item evrak"><el-radio-button label="2" class="giden">Giden Evraklar</el-radio-button></el-badge>
                        </el-radio-group>
                    </div>    
                </el-col>
               <el-col :lg="12" :md="12" :sm="24" :xs="24" v-if="yetkiTuru != '3'">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler">
                            <li>
                                <i class="mdi mdi-download mdi-18px" style="color: #036fad"></i> 
                                <span>Evrak İndir</span>
                            </li>                            
                            <li>
                                <i class="mdi mdi-account mdi-18px" style="color: #5c5c61"></i> 
                                <span>Yetkili Görüntüle</span>
                            </li>                
                            <li>
                                <i class="mdi mdi-pen mdi-18px" style="color: #f7ba2a"></i> 
                                <span>Evrak Güncelle</span>
                            </li>                            
                            <li>
                                <i class="mdi mdi-delete mdi-18px" style="color: #ec205f "></i> 
                                <span>Evrak Sil</span>
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>
            <el-table v-loading="searchLoading" border stripe :data="evrakListe" style="width: 100%"
            :element-loading-text='"Kurum Aranıyor. Lütfen bekleyin."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
               <!--
                <el-table-column>
                    <template slot="header">
                    </template>
                    <el-table-column
                        :label='$t("src.views.apps.genel.sira")'
                        type="index"
                        :index="indexMethod"
                        width="70">
                    </el-table-column>
                </el-table-column>
                
                
                <el-table-column>
                        <el-table-column label='Dosya' align="left" width="150">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="!scope.row.newRow && scope.row.dosyaAdi != ''"
                                    @click="downloadFile(scope.row);"
                                    type="text"
                                    class="indir"
                                    style="margin-left: 5px !important;">
                                    <i class="mdi mdi-download mdi-24px"></i>
                                    <span class="indir-text">Dosyayı İndir</span>
                                </el-button>
                                    <el-upload v-if="scope.row.newRow || scope.row.dosyaAdi == ''"
                                        id="evrakEkle"
                                        action=""
                                        :auto-upload="false"
                                        :limit="1"
                                        :file-list="evrakListMain"
                                        :on-change="evrakUpload"
                                        :on-remove="evrakRemove"
                                        list-type="belge-card"
                                        accept="">
                                        <el-button style="background-color: red;" size="mini" type="primary" icon="el-icon-upload">Dosya Yükle</el-button>
                                    </el-upload>
                                
                            </template>
                        </el-table-column>
                </el-table-column>
-->
                <el-table-column>
                    <el-table-column label='Dosya' align="left" width="150">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.dosyaAdi != ''"
                                @click="downloadFile(scope.row);"
                                type="text"
                                class="indir">
                                <i class="mdi mdi-download mdi-20px"></i>
                                <span class="indir-text">Dosyayı İndir</span>
                            </el-button>
                            <el-upload 
                                class="upload"
                                v-if="scope.row.dosyaAdi == ''"
                                :id="'evrakEkle_' + scope.row.uniqueId" 
                                action=""
                                :auto-upload="false"
                                :limit="1"
                                :file-list="scope.row.evrakList" 
                                :on-change="file => evrakUpload(file, scope.row)"
                                :on-remove="evrakRemove"
                                list-type="belge-card"
                                accept="">
                                <el-button v-if="yetkiTuru != '3'" style="background-color: red;" size="mini" type="primary" icon="el-icon-upload">Dosya Yükle</el-button>
                            </el-upload>
                        </template>
                    </el-table-column>
                </el-table-column>


                <el-table-column>                    
                    <template slot="header" slot-scope="props">
                        <el-input clearable v-on:clear="evrakAra" placeholder='Evrak No Ara' v-model="evrakNoAra" v-debounce:500ms="evrakAra" size="mini"></el-input>
                    </template>
                    <el-table-column
                        prop="kayitKodu"
                        label='Evrak No'
                        width="200"
                        :formatter="formatEvrakNo">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.newRow">{{ yeniKayitKodu }}</span>
                                <span v-else>{{ formatEvrakNo(scope.row) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column>
                    <template slot="header" slot-scope="props">
                        <el-input clearable v-on:clear="evrakAra" placeholder='Evrak Konu Ara' v-model="evrakKonuAra" v-debounce:500ms="evrakAra" size="mini"></el-input>
                    </template>
                    <el-table-column 
                        prop="konu"
                        label="Evrak Konu"
                        width="auto">
                        <template slot-scope="scope">
                            <div v-if="!tiklama && !scope.row.newRow" class="" @dblclick="editKonu(scope.row)">
                                {{ scope.row.konu }}
                            </div>

                            <div v-if="tiklama">
                                <el-input                                
                                    v-model="scope.row.konu"
                                    size="mini"
                                    @blur="saveEditedKonu(scope.row)">
                                </el-input>
                            </div>

                            <div v-if="scope.row.newRow && !onaylaEvrakKonuTiklandi">
                                <el-col :lg="20">
                                    <el-input style="width: 90%" placeholder='Evrak Konu Giriniz' v-model="scope.row.konu" size="mini"></el-input>
                                </el-col>
                                <el-col :lg="2">
                                    <el-tooltip content='Onayla' :open-delay="500" placement="bottom">
                                        <el-button @click="onaylaEvrakKonu" type="primary" size="small" icon="el-icon-check"></el-button>
                                    </el-tooltip>
                                </el-col>
                            </div>

                            <div class="new-line-text" v-if="scope.row.newRow && onaylaEvrakKonuTiklandi">                                
                                <span >{{scope.row.konu}}</span>
                            </div>
                          
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column>
                    <template slot="header" slot-scope="props">
                        <el-input clearable v-on:clear="evrakAra" placeholder='Cari Ara' v-model="cariAra" v-debounce:500ms="evrakAra" size="mini"></el-input>
                    </template>
                    <el-table-column 
                        prop="kurumAdi"
                        label="Cari Adı"
                        width="auto">
                        <template slot-scope="scope">
                            <div v-if="scope.row.newRow && !onaylaCariTiklandi" class="new-line-text">
                                <el-col :lg="20">
                                    <el-select ref="kurumSelect" style="width: 90%" size="small"  v-model="scope.row.kurumAdi" placeholder="Cari Seçiniz" filterable :filter-methos="filterMethod">
                                        <el-option
                                            v-for="item in kurumListe" 
                                            :key="item.kurumID"
                                            :label="item.kurumAdi"
                                            :value="item.kurumAdi">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :lg="2">
                                    <el-tooltip content='Onayla' :open-delay="500" placement="bottom">
                                        <el-button @click="onaylaCari" type="primary" size="small" icon="el-icon-check"></el-button>
                                    </el-tooltip>
                                </el-col>
                            </div>

                            <div class="new-line-text" v-if="scope.row.newRow && onaylaCariTiklandi" >                                
                                <span >{{scope.row.kurumAdi}}</span>
                            </div>

                            <div class="new-line-text"  v-if="!scope.row.newRow">                                
                                <span >{{scope.row.kurumAdi}}</span>
                            </div>

                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column>
                    <template slot="header" slot-scope="props">
                        <el-date-picker @change ="evrakAra" placeholder='Tarih Ara' v-model="tarihAra" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="{ firstDayOfWeek: 1 }" size="mini" class="select-wide" type="date"></el-date-picker>
                    </template>  
                    <el-table-column
                        prop="islemTarihi"
                        label='Evrak Tarihi'
                        width="300">
                        <template slot-scope="scope">
                            <div v-if="scope.row.newRow && !onaylaTarihTiklandi">
                                <el-col :lg="20">
                                    <el-date-picker 
                                        style="width: 90%;"                                    
                                        v-model="scope.row.islemTarihi"
                                        size="small" class="select-wide" 
                                        type="date"
                                        :placeholder='"Tarih Seçiniz"'
                                        format="dd.MM.yyyy"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="{ firstDayOfWeek: 1 }">
                                    </el-date-picker>
                                </el-col>
                                <el-col :lg="2">
                                    <el-tooltip content='Onayla' :open-delay="500" placement="bottom">
                                        <el-button @click="onaylaTarih" type="primary" size="small" icon="el-icon-check"></el-button>
                                    </el-tooltip>
                                </el-col>
                            </div>
                            <div v-else>                                
                                <span >{{ formatDate2(scope.row.islemTarihi) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>

                <!--
                <el-table-column v-if="addTiklandi">  
                    <el-table-column
                        label='Kaydet'
                        width="150">
                        <template slot-scope="scope">
                            
                        </template>
                    </el-table-column>
                </el-table-column>
                -->

                <el-table-column v-if="yetkiTuru != '3'">
                        <el-table-column :label='$t("src.views.apps.genel.islem")' align="right" width="200">
                            <template slot-scope="scope">
                                <el-tooltip :content="scope.row.yoneticiAdi + ' - ' + formatDate2(scope.row.kayitTarihi)"  :open-delay="500" placement="bottom" v-if="!scope.row.newRow">
                                    <el-button
                                        type="text"
                                        style="color:#5c5c61; margin-left: 15px !important;"
                                        icon="mdi mdi-account mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content='Evrak Güncelle' :open-delay="500" placement="bottom" v-if="!scope.row.newRow">
                                    <el-button v-if="yetkiTuru == 1 || (yetkiTuru == 2 && sonDosyaID == scope.row.dosyaID)"
                                        v-on:click="updatePage(scope.row); "
                                        type="text"
                                        style="color: #f7ba2a; margin-left: 15px !important;"
                                        icon="mdi mdi-pen mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.rakamlar.islem.sil")' :open-delay="500" placement="bottom" v-if="!scope.row.newRow">
                                    <el-button v-if="yetkiTuru == 1 || (yetkiTuru == 2 && sonDosyaID == scope.row.dosyaID)"
                                        v-on:click="dosyaSil(scope.row.dosyaID)"
                                        type="text"
                                        class="buttonDel"
                                        style="margin-left: 15px !important;"
                                        icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>                                
                                <el-tooltip content='Boş Evrak Ekle' :open-delay="500" v-if="scope.row.newRow">
                                    <el-button
                                        v-on:click="bosEvrakTanimla(scope.row.konu, scope.row.kurumAdi, scope.row.islemTarihi)"
                                        size="small"
                                        type="primary" 
                                        style="margin-left: auto; margin-right: 0; margin-top: 5px; margin-bottom: 5px; display: block; text-align: right;" >Kaydet
                                    </el-button>
                            </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
            </el-table>
            
            <div v-if="this.evrakListe.length == []"  style="width: 100%; height: 50px !important; display: flex; justify-content: center; align-items: center">
                    {{$t("src.views.apps.messages.noData")}}
            </div>
            
           <el-row>
                <!--
                <el-col :lg="12" :md="12" :sm="12" :xs="12" >
                    <el-button size="small" type="primary" style="margin: 10px;" @click="addRow">+</el-button>
                </el-col>
                -->
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                    <el-pagination
                        background
                        layout="sizes,prev, pager, next, jumper, total"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="pageChange"
                        :page-sizes="[20, 50, 100]"
                        :current-page.sync="page"
                        :next-click.sync="page"
                        :prev-click.sync="page"
                        @next-click="pageChange"
                        @prev-click="pageChange">
                    </el-pagination>
                </el-col>
           </el-row>

        </div>
    </div>
</template>

<script>
    import kurumService from '../../../WSProvider/KurumService'
    import dosyaService from '../../../WSProvider/DosyaService'
    import EventBus from '@/components/event-bus'
    import notification from '../../../notification'
    import functions from '../../../functions'    
    import jwt_decode from "jwt-decode"

    var moment = require('moment');

    export default {
        name: "EvrakListesi",
        data() {
            return {
                loading: false,
                stateLoading: false,
                searchLoading: false,
                kurumLoading: false,
                evrakLoading: false,
                addLoading: false,
                tiklama: false,
                konuLoading: false,
                onaylaCariTiklandi: false,
                onaylaEvrakKonuTiklandi: false,
                onaylaTarihTiklandi: false,
                addTiklandi: false,
                dosyaYukleme: false,

                //editDialogLoading: false,
                editDialog:false,
                editDialogData: {},
                
                musteriList: [],
                evrakListe: [],
                kurumListe: [],                
                evrakListMain: [],
                
                radio: '1',
                evrakForm: {
                    evrakTarihi: "",                        
                    durum: '1',
                    kurumID: "",
                    evrak: [],
                    evrakID: '',
                    evrakAdi: ''
                },
                rulesMusteri: {},
                
                sizes: 20,
                evrakDurumu: '1',
                kurumDurumu: "",
                evrakKonu: "",
                page: 0,
                postPage: 0,
                yetkiTuru: "",
                total: 4,
                evrakNoAra: "",
                evrakKonuAra: "",
                cariAra: "",
                tarihAra: "",
                evrakAdiAra: "",
                yeniKayitKodu: "",
                gelenCount: "",
                gidenCount: "",
                sonDosyaID: "",
                secilenKurum: ""
            }
        },

        created(){
            if (localStorage.getItem("userDataDemirag")) {
                this.yetkiTuru = this.$store.getters.getYetkiTuru;                 
            }


            EventBus.$on("evrakList", () => {
                this.refreshPage()
            });

        },        

        mounted() {
            //this.getMusteriListesi();
            //this.getMusteriProjeListesi();
            this.yetkiTuru = this.$store.getters.getYetkiTuru 
            this.getDosyaList();
        },
        
        methods: {

            
            evrakUpload(file, row, formName) {
                this.$set(row, 'dosyaYuklendi', true); 

                const item = {
                    name: file.name,
                    url: file.url,
                    id: file.raw.lastModified,
                    size: file.raw.size
                };

                this.evrakListMain.push(item);

                file.raw["tmp_name"] = file.url;

                this.evrakForm.evrak.push(file.raw);
                this.$forceUpdate();
                //row.dosyaYuklendi = false;

                        this.$confirm("Evrak Eklemek İstediğinize Emin Misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            this.evrakLoading = true;
                            dosyaService.dosyaGuncelle(row.dosyaID, row.kurumID, row.konu, this.evrakForm.evrak[0]).then((response) => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)                                    
                                    notification.Status("success", this, response.msg)
                                    this.refreshPage()
                                    this.resetForm(formName)
                                    
                                }
                                this.evrakLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.evrakLoading = false;
                            })
                        }).catch((e) => {})
                                     

            },

       

            editKonu(row) {
                this.tiklama = true;
                row.editing = true;
            },

            onaylaCari(){
                this.onaylaCariTiklandi = true
                this.secilenKurum = this.$refs.kurumSelect.selectedLabel;
            },

            onaylaEvrakKonu(){
                this.onaylaEvrakKonuTiklandi = true
                //this.secilenKurum = this.$refs.kurumSelect.selectedLabel;
            },

            onaylaTarih(){
                this.onaylaTarihTiklandi = true
                //this.secilenKurum = this.$refs.kurumSelect.selectedLabel;
            },
            
            saveEditedKonu(row) {
                this.tiklama = false;
                row.editing = false;
                
                this.konuLoading = true;
                dosyaService.dosyaGuncelle2(row.dosyaID, row.konu).then((response) => {
                    if(response.status == 200){
                        localStorage.setItem("userDataDemirag", response.token)
                    }
                    this.konuLoading = false;
                    notification.Status("success", this, response.msg)
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.konuLoading = false;
                })     
            },

            addRow() {
                this.addTiklandi = true
                if(this.evrakListe.length != 0) {
                    let maxSiraNo = Math.max(...this.evrakListe.map((evrak) => evrak.dosyaID), 0);
                    let foundEvrak = this.evrakListe.find((evrak) => evrak.dosyaID == maxSiraNo);
                    let kayitKodu = foundEvrak ? this.formatEvrakNo(foundEvrak) : null;
                    const numberPart = parseInt(kayitKodu.substring(1));
                    const charPart = kayitKodu.substring(0,1);
                    const yeniKayitKodu = `${charPart}${numberPart + 1}`;
                    this.yeniKayitKodu = yeniKayitKodu
                    this.evrakListe.push({newRow: true, dosyaAdi: '' }); 
                    this.getKurumList();
                }
                else{
                    const yeniKayitKodu = `${this.evrakDurumu}1`;
                    this.yeniKayitKodu = yeniKayitKodu
                    this.evrakListe.push({newRow: true, dosyaAdi: '' }); 
                    this.getKurumList();
                }
                
            },


            bosEvrakTanimla(evrakKonu, kurum, tarih) {
                if(evrakKonu != undefined && !this.onaylaEvrakKonuTiklandi){
                    notification.Status("warning", this, "Lütfen evrak konusunu onaylayınız.")                                    
                    this.addLoading = false;
                    return;
                }
                else if(kurum != undefined && !this.onaylaCariTiklandi){
                    notification.Status("warning", this, "Lütfen cariyi onaylayınız.")                                    
                    this.addLoading = false;
                    return;
                }
                else if(tarih != undefined && !this.onaylaTarihTiklandi){
                    notification.Status("warning", this, "Lütfen tarihi onaylayınız.")                                    
                    this.addLoading = false;
                    return;
                }
                else if(tarih == "" || tarih == undefined || !tarih){                                                                  
                    notification.Status("warning", this, "Lütfen tarih seçiniz.")                                    
                    this.addLoading = false;
                    return;
                }
                this.openConfirmDialog("Boş evrak eklemek istediğinize emin misiniz?").then(() => {
                    try{
                        this.addLoading = true;
                        dosyaService.bosDosyaEkle(this.evrakDurumu, this.evrakForm.evrak[0] != undefined ? this.evrakForm.evrak[0] : "", evrakKonu != undefined ? evrakKonu : "", kurum != undefined ? kurum : "", tarih != undefined ? tarih : "").then(response => {
                                                  
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)                                    
                                    notification.Status("success", this, response.msg)                                    
                                    this.refreshPage();
                                    this.resetForm(formName)
                                }
                            this.addLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                console.log(error)
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else {
                                    if(error.status == 400){
                                        notification.Status("warning", this, error.errMsg)
                                    }
                                    if(error.status == 503){
                                        notification.Status("warning", this, error.msg)
                                    }
                                }
                            }else{
                                //notification.Status(503, this)
                            }
                            this.addLoading = false;
                        })
                    }catch(e){
                        this.addLoading = false;
                    }
                })                
            },

           
            getDosyaList() {
                try{
                    this.loading = true;
                    this.evrakDurumu == 2 ? this.evrakDurumu  = "O": this.evrakDurumu = "I"
                    dosyaService.dosyaListele(this.postPage, this.sizes, this.evrakDurumu).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.evrakListe = response.data;
                            this.total = parseInt(response.count);
                            this.gelenCount = parseInt(response.gelenCount);
                            this.gidenCount = parseInt(response.gidenCount);
                            this.sonDosyaID = Math.max(...this.evrakListe.map((evrak) => evrak.dosyaID), 0);
                            this.evrakListe.forEach((row) => {
                                row.dosyaYuklendi = false
                            })
                        }
                        //console.log(this.evrakListe)
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.evrakListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            filterMethod(query, item) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            },
            
            getKurumList() {
                try{
                    this.kurumLoading = true;
                    this.kurumDurumu = this.evrakDurumu  == "I" ? 1 : 2
                    kurumService.kurumListele(this.kurumDurumu,  this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.kurumListe = response.data;        
                        }
                        this.kurumLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kurumListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.kurumLoading = false;
                    })
                }catch(e){
                    this.kurumLoading = false;
                }
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
                this.evrakListMain = [];

            },

            evrakRemove() {
                $('#evrakEkle .el-upload--belge-card').show();
                this.evrakListMain = [];
                this.evrakForm.evrak = [];
            },

            

            downloadFile(item){
                var downloadLink = document.createElement("a");
                downloadLink.href = dosyaService.dosyaPath + item.dosyaAdi;
                downloadLink.download = item.dosyaAdi;
                downloadLink.target = "_blank"; 
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },

            dosyaSil(dosyaID) {
                var msg =  "Evrakı silmek istediğinize emin misiniz?" 
                this.openConfirmDialog(msg).then(() => {
                    try{
                        this.stateLoading = true;
                        dosyaService.dosyaSil(dosyaID).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem("userDataDemirag", response.token)
                                this.getDosyaList();
                                this.stateLoading = false;
                            }
                            notification.Status("success", this, response.msg)
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("warning", this, error.msg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false;
                    }
                })                
            },


            updatePage(data) {
                this.$store.commit('changeEvrakUpdateData', data.dosyaID)
                functions.routeSayfa("Evrak Güncelle",this)
            },
            
            //kurumAdi, konu, kayitKodu, dosyaDurumu, islemTarihi, baslangic, limit
            evrakAra(){
                this.postPage = 0;
                this.page = 1
                this.dateVisible = false;
                //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
                if(this.evrakNoAra.length > 0 || this.evrakKonuAra.length > 0 || this.cariAra.length > 0 || this.tarihAra || this.evrakAdiAra.length > 0 ){
                    try{
                        this.searchLoading = true;
                        dosyaService.dosyaArama(this.cariAra, this.evrakKonuAra, this.evrakNoAra, this.evrakDurumu, this.tarihAra, this.postPage, this.sizes).then(response => {
                            if(response.status == 200){
                                this.evrakListe = response.data;
                                this.total = response.count
                            }
                            localStorage.setItem("userDataBGSurec", response.token);
                            this.searchLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }else if(error.status == 404){
                                    this.evrakListe = []
                                }
                            }else{
                                //notification.Status(503, this)
                            }
                            this.searchLoading = false;
                        })
                    }catch(e){
                        // notification.Status("danger", this, "An error occurred during the customer search process.")
                        this.searchLoading = false
                    }
                }else{
                    this.getDosyaList(); 
                }
            },

            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.loading = false;                
                this.getDosyaList();
                this.stateLoading = false;  
                this.evrakDurumu = "I"
                
                // this.getMusteriSayisi();
                //this.getMusteriListesi();
                //this.getMusteriProjeListesi();
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getDosyaList();
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getDosyaList();
            },



            vazgec(formName){
                this.$refs[formName].resetFields();
                this.editDialog = false;
                this.loading = false;
                this.stateLoading = false;                
                this.evrakListMain = [];
                this.evrakForm.evrak = []
            },

            formatEvrakNo(row) {
                if (row.kayitKodu) {
                    const parts = row.kayitKodu.split("-");
                    return parts[0];
                } else {
                    return '';
                }
            },

            formatDate2(date) {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                return new Date(date).toLocaleDateString('tr-TR', options);
            },

            changeMusteriDurum(selection, durum) {
                this.$confirm("Müşterinin durumunu güncellemek istediğinizden emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                    confirmButtonText: this.$t("src.views.apps.genel.yes"),
                    cancelButtonText: this.$t("src.views.apps.genel.no"),
                    type: 'warning'
                }).then(() => {
                    try{
                        this.stateLoading = true;
                        var item = { durum : durum }
                        kurumService.musteriGuncelle(selection.musteriID, (item)).then(response => {
                            if(response.status == 200){
                               // this.getMusteriListesi();
                                //this.getMusteriProjeListesi();
                            }
                            this.stateLoading = false;
                            notification.Status("success", this, response.msg);
                            localStorage.setItem("userDataBGSurec", response.token);
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        // notification.Status("success", this, "An error occurred while fetching the customer number.");
                        this.stateLoading = false;
                    }
                })
            },

                       
            routeKurum(name, scope) {
                if (scope) {
                    this.$store.commit('changeKurumUpdateData', scope);
                }
                functions.routeSayfa(name, this);
            },

            indexMethod(index) {
                return ((this.page - 1)* 10) + index + 1;
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getDosyaList();
                //this.getMusteriListesi();
               // this.getMusteriProjeListesi();
            },

            handleChange(event) {
                this.evrakDurumu = event;
                this.page = 1;
                this.postPage = 0;
                this.getDosyaList();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
                this.kurumAdiAra = ""
                this.unvanAra = ""
                this.adresAra = ""
                this.telefonAra= ""
                this.epostaAra= ""
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getDosyaList();
            },

            formatDate(row, column) {
                const date = row.islemTarihi;
                return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
            },

        }
    }

</script>

<style lang="scss">
    .el-table .info-row {
        background: rgba(125, 199, 225, 0.45);
    }

    .el-table .error-row {
        background: rgba(249, 0, 26, 0.08);
    }

    .el-table .success-row {
        background: rgba(3, 249, 0, 0.08);
    }

    .el-table .warning-row {
        background: rgba(245, 249, 0, 0.08);
    }
</style>
<style lang="scss" scoped>
    .refresh {
        color: #fff;
        float: right;
        padding: 0 !important;
        transition: all 0.5s;

    }

    .refresh:hover {
        transform: rotate(360deg);
    }

    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }

    .kaldirilmis {
        background: #ec205f;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 10px
    }

</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
<style>
.el-badge__content {
    background-color: red !important;
    color: white;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 450;
    margin-bottom: -20px !important;
}

.evrak .el-badge__content.is-fixed {
    position: absolute;
    top: 0;
    right: 10px;
    -webkit-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(-550%);
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #FFFFFF;
    text-align: left;
    /* padding-left: 0; */
    /*background-color: #90c4a1;*/
    background-color: #3b94bd;
    border-color: #3b94bd;
    -webkit-box-shadow: -1px 0 0 0 #3b94bd;
    box-shadow: -1px 0 0 0 #3b94bd;
}


.indir { 
    margin-left: 2px !important;
    padding-right: 10px !important;
    padding-top: 5px;
    padding-bottom: 0 !important;
    margin-bottom: 1px !important;
    position: relative;
    display: inline-block;
}

.upload{
    line-height: 40px;
}

.indir .mdi-download {
    font-size: 23px;
}

.indir .indir-text {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translateY(-50%) translateX(-5px);
    padding: 5px 20px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
}


.indir:hover .indir-text {
    opacity: 100;
    transform: translateY(-50%) translateX(0);  
    transition-duration: 0.9s;  
    color: red;
}



.el-button--mini {
    padding: 7px 5px;
    font-size: 16px;
    border-radius: 3px;
}


</style>